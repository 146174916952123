<template>
  <aside class="page__sidebar">
    <div class="sidebar__header">
      <div @click="changeAvatar" class="avatar">
        <i class="fa fa-pen edit-avatar-icon"></i>
        <img v-if="user.avatar" :src="user.avatar" />
        <span v-else>{{ user.name[0] }}</span>
      </div>
      <input
        @change="handleFile"
        type="file"
        hidden
        ref="avatarFile"
        accept="image/png,image/jpeg"
      />

      <h1 class="sidebar__title">{{ user.name }}</h1>
      <h5 class="sidebar__subtitle">{{ user.email }}</h5>
    </div>

    <div class="sidebar__nav">
      <a
        class="sidebar__nav--anchor"
        href="#endereco"
        :class="{ current: ['', '#endereco'].includes(`${current}`) }"
        title="Endereço"
      >
        Endereço
      </a>
      <a
        class="sidebar__nav--anchor"
        href="#dados-pessoais"
        :class="{ current: current === '#dados-pessoais' }"
        title="Dados Pessoais"
      >
        Dados Pessoais
      </a>
      <a
        class="sidebar__nav--anchor"
        href="#dados-de-acesso"
        :class="{ current: current === '#dados-de-acesso' }"
        title="Dados de Acesso"
      >
        Dados de Acesso
      </a>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert'

export default {
  data: () => ({
    loading: false,
    form: { file: {} }
  }),
  computed: {
    ...mapGetters('user', ['user']),
    current () {
      return this.$route.hash
    }
  },
  methods: {
    changeAvatar () {
      this.$refs.avatarFile.click()
    },
    handleFile (e) {
      if (this.loading) return
      this.form.file = {}
      const files = e.target.files
      if (!files.length) return
      const [file] = files

      const { type, size } = file
      const acceptedTypes = ['image/png', 'image/jpeg']

      if (!acceptedTypes.includes(type)) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'Os arquivos aceitos são no formato PNG e JPG.',
          icon: 'error'
        })
      }

      if (size > 2097152) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'O arquivo deve ser menor que 2MB.',
          icon: 'error'
        })
      }

      this.form.file = file
    },
    async sendFile () {
      const form = new FormData()
      form.append('file', this.form.file)
      const url = 'auth/user/avatar'
      const response = await this.$http.post(url, form)
      const avatarUrl = response.data.avatar_url
      const localUser = JSON.parse(localStorage.getItem('user'))
      localUser.avatar = avatarUrl
      localStorage.setItem('user', JSON.stringify(localUser))
      window.location.reload()
    }
  },
  watch: {
    'form.file': {
      deep: true,
      handler () {
        this.sendFile()
      }
    }
  }
}
</script>

<style scoped>
.sidebar__header {
  text-align: center;
}

.avatar {
  overflow: hidden;
  margin: 0 auto 1rem auto;
  width: 125px;
  height: 125px;
  background: #47be71;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.avatar span {
  font-size: 50px;
  color: white;
  font-weight: bold;
}

.avatar:hover .edit-avatar-icon {
  opacity: 1;
}

.avatar .edit-avatar-icon {
  position: absolute;
  opacity: 0;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  transition: 0.25s;
  transform-origin: center;
}
</style>
